/** @jsxRuntime classic */
/** @jsx jsx */ 
import { jsx, css } from '@emotion/react'
import { useState, useEffect } from 'react'
import { Slider, Grid, Typography, Box } from '@material-ui/core'
import RemoveIcon from '@material-ui/icons/Remove'
import AddIcon from '@material-ui/icons/Add'
import PropTypes from 'prop-types'
import formatNumber from '../../helpers/formatNumber'
import { useTheme } from '@findep/microfronts-core'

export default function SliderRange(props) {
  const { palette } = useTheme()

  const principal = css`
    min-width: 100% !important;
    width:100%!important
  `
  const root = css`
  margin-left: 0.5rem!important;
    color: ${palette.primary.main} !important;
    & span {
      ${props.disabled ? 'color: rgba(0, 0, 0, 0.38) !important;' : 'color: ${palette.primary.main} !important;'}
    }
    ${props.disabled && 'color: rgba(0, 0, 0, 0.38) !important;'}
  `

  const styleSlider = css`
    color: ${palette.primary.main};
  `
  const rootDisplayMount = css`
    margin-bottom: 0 !important;
    font-weight: bold !important;
    color: #e66728;
    ${props.disabled && 'color: rgba(0, 0, 0, 0.38) !important;'}
  `

  const generalIcons = css`
    color: white;
    background: ${palette.primary.main};
    border-radius: 50%;
    width: 1rem !important;
    height: 1rem !important;
  `

  const disabledIcons = css`
    pointer-events: none;
    color: rgba(0, 0, 0, 0.38);
    background-color: rgba(0, 0, 0, 0.12);
    border-radius: 50%;
    width: 1rem !important;
    height: 1rem !important;
  `
  const styleIcons = css`
    cursor: pointer;
    ${props.disabled ? disabledIcons : generalIcons}
  `
  const [state, setState] = useState({
    max:props.max,
    min:props.min,
    step:props.step,
    defaultValue:props.defaultValue
  })
  const [mount, setMount] = useState(props.rangeValues ? 0 : props.min)
  const [aux, setAux] = useState(props.rangeValues ? 0 : props.min)

  useEffect(() => {
    let max = props.rangeValues ? props.rangeValues.length - 1 : props.max
    let min = props.rangeValues ? 0 : props.min
    if(props.rangeValues){
      max = props.rangeValues.length - 1
      min = 0
      setState({
        max,
        min,
        step:1,
        defaultValue: props.defaultValue
      })
    }
    if (state.defaultValue) {
      if (state.defaultValue >= min && state.defaultValue <= max) {
        setMount(state.defaultValue)
        setAux(state.defaultValue)
      } else {
        throw new Error('defautValue no respeta los limites max/min o no coincide con steper code Mount')
      }
    }
  }, [])

  useEffect(() => {
    let max = props.rangeValues ? props.rangeValues.length - 1 : props.max
    let min = props.rangeValues ? 0 : props.min

    if(props.rangeValues){
      setState({
        max,
        min,
        step:1,
        defaultValue: props.defaultValue 
      })
    }else{
      setState({
        max,
        min,
        step:props.step,
        defaultValue: props.defaultValue 
      })
    }

    if (props.defaultValue) {
      if (props.defaultValue >= min && props.defaultValue <= max) {
        setMount(props.defaultValue)
        setAux(props.defaultValue)
      } else {
        throw new Error('defautValue no respeta los limites max/min o no coincide con steper code Update')
      }
    }else{
      setMount(min)
      setAux(min)
    }
  }, [props.min, props.max, props.defaultValue, props.rangeValues])

  useEffect(() => {
    props.onChange({ mount })
  }, [mount])

  const handleSliderChange = (_event, newValue) => {
    setMount(newValue)
    setAux(newValue)
  }

  const handleChangeMount = (flag) => {
    const newMount = Number(flag) + aux
    if (flag < 0) {
      if (aux >= state.min) {
        if (newMount >= state.min) {
          setMount(newMount)
          setAux(newMount)
        } else {
          if (!(aux === state.min)) {
            setMount(state.min)
            setAux(newMount)
          }
        }
      }
    } else {
      if (aux <= state.max) {
        if (newMount <= state.max) {
          setMount(newMount)
          setAux(newMount)
        } else {
          if (!(aux === state.max)) {
            setMount(state.max)
            setAux(newMount)
          }
        }
      }
    }
  }

  return (
    <Box css={principal}>
      <Typography
        id='displayMount'
        gutterBottom        
        variant='h4'
        fontFamily='roboto'
        css={rootDisplayMount}
        fontWeight='fontWeightBold'
        align='center'
      >
        <Box textAlign='center'>
          { props.alternalText ? props.alternalText : formatNumber(props.rangeValues ? props.rangeValues[mount] : mount)}
        </Box>
      </Typography>
      <Grid container spacing={1}  alignItems='center' justify='center'>
        <Grid item xs={1}>
          <RemoveIcon
            id={`${props.id}-BtnMinus-button`}
            onClick={() => handleChangeMount(-state.step)}
            css={styleIcons}
          />
        </Grid>
        <Grid item xs={8} css={root}>
          <Slider
            css={styleSlider}
            id={`${props.id}-input`}
            value={typeof mount !== 'number' ? state.min : mount}
            step={state.step}
            max={state.max}
            min={state.min}
            onChange={handleSliderChange}
            disabled={props.disabled}
            
          />
        </Grid>
        <Grid item xs={1}>
          <AddIcon id={`${props.id}-BtnPlus-button`} onClick={() => handleChangeMount(state.step)} css={styleIcons} />
        </Grid>
      </Grid>
    </Box>
  )
}

SliderRange.propTypes = {
  max: PropTypes.number,
  min: PropTypes.number,
  step: PropTypes.number,
  defaultValue: PropTypes.number,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  id: PropTypes.string,
  alternalText: PropTypes.node,
  rangeValues: PropTypes.array,
}
SliderRange.defaultProps = {
  max: 100,
  min: 10,
  step: 10,
  disabled: false,
  onChange: () => {},
  id: 'slider-range'
}


